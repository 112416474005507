import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import $ from 'jquery';

function Navigation() {
    const { t } = useTranslation();

    const scrollTo = (evt, elId) => {
        evt.preventDefault();
        window.scrollTo({top: document.getElementById(elId).offsetTop - (window.innerWidth >= 768 ? 100 : 50), behavior: 'smooth'})
        toggleNavbar();
    }

    const toggleNavbar = () => {
        $('.navbar-toggle:visible').click();
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(toggleNavbar)
    }

    const languageButtons =
        ['en', 'es', 'it'].map( language => <li key={language}>
            <button onClick={evt => changeLanguage(language)} className="btn-lang">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        className={`flag flag-${language}`} />
                </button>
            </li>);
    
    return (
        <div>
            <div id="skipnav"><a href="#maincontent">{t('SkipToMainContent')}</a></div>

            {/* Navigation */}
            <nav id="mainNav" className="navbar navbar-default navbar-fixed-top navbar-custom">
                <div className="container">
                    {/* Brand and toggle get grouped for better mobile display */}
                    <div className="navbar-header page-scroll">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">{t('ToggleNav')}</span> Menu <i className="fa fa-bars"></i>
                        </button>
                        <a onClick={ evt => scrollTo(evt, 'root')} className="navbar-brand" href="#root">{t('Name')}</a>
                    </div>

                    {/* Collect the nav links, forms, and other content for toggling */}
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-left">
                            {languageButtons}
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li className="hidden">
                                <a onClick={ evt => scrollTo(evt, 'root')} href="#root"></a>
                            </li>
                            <li className="page-scroll">
                                <a onClick={ evt => scrollTo(evt, 'portfolio')} href="#portfolio">{t('Portfolio')}</a>
                            </li>
                            <li className="page-scroll">
                                <a onClick={ evt => scrollTo(evt, 'about')} href="#about">{t('About')}</a>
                            </li>
                            <li className="page-scroll">
                                <a onClick={ evt => scrollTo(evt, 'contact')} href="#contact">{t('Contact')}</a>
                            </li>
                        </ul>
                    </div>
                    {/* /.navbar-collapse */}
                </div>
                {/* /.container-fluid */}
            </nav>
        </div>
    )
}

export default Navigation;
