import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

    return (
        <footer className="text-center">
        <div className="footer-above">
          <div className="container">
            <div className="row">
              <div className="footer-col col">
                <h3>{t('FooterHeading')}</h3>
                <ul className="list-inline">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/fabio-catuogno/?locale=en_US" className="btn-social btn-outline"><span className="sr-only">{t('LinkedIn')}</span><i className="fa fa-fw fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a href="skype:fabcat555?call" className="btn-social btn-outline"><span className="sr-only">{t('Skype')}</span><i className="fa fa-fw fa-skype"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href="https://twitter.com/FabCat555" className="btn-social btn-outline"><span className="sr-only">{t('Twitter')}</span><i className="fa fa-fw fa-twitter"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href="https://github.com/fabcat555" className="btn-social btn-outline"><span className="sr-only">{t('Github')}</span><i className="fa fa-fw fa-github"></i></a>
                  </li>
                  <li>
                    <a href="mailto:catuogno.fab@gmail.com" className="btn-social btn-outline"><span className="sr-only">{t('Mail')}</span><i className="fa fa-fw fa-envelope-o"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;