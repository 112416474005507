import React from 'react';
import { useTranslation } from 'react-i18next';

function Header() {
    const { t } = useTranslation();

    return (
        <header>
            <div className="container" id="maincontent" tabIndex="-1">
                <div className="row">
                    <div className="col-lg-12">
                        <img className="img-circle" src="assets/img/profile.png" alt="Profile img" />
                        <div className="intro-text">
                            <h1 className="name">{t('Name')}</h1>
                            <hr className="star-light" />
                            <span className="skills">{t('Skills')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;