import React from 'react';
import './App.css';

import Navigation from './components/Navigation';
import Header from './components/Header';
import PortfolioGrid from './components/PortfolioGrid';
import PortfolioModals from './components/PortfolioModals';
import About from './components/About';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Header />
      <PortfolioGrid />
      <PortfolioModals />
      <About />
      <ContactMe />
      <Footer />
      {/* Scroll to Top Button (Only visible on small and extra-small screen sizes) */}
      <div className="scroll-top page-scroll hidden-sm hidden-xs hidden-lg hidden-md">
        <a className="btn btn-primary" href="#page-top">
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
  );
}

export default App;
