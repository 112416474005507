import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from "./i18n/en/common.json";
import common_es from "./i18n/es/common.json";
import common_it from "./i18n/it/common.json";

import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    defaultNS: ['common'],
    whitelist: ['en', 'es', 'it'],
    debug: false,
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        es: {
            common: common_es
        },
        it: {
            common: common_it
        }
    }
  });


export default i18n;