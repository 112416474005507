import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function ContactMe() {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setBlocking(true);

    var data = {
      subject: "Fabio Catuogno Website",
      access_token: "0ikv5c2mn9q6aedwnc9peac"
    };
    data.text = message + '\r\n\n' + "Reply to: " + email + '\r\n\n' + name;

    axios.post('https://postmail.invotes.com/send', data)
      .then(() => onFormSubmitSuccess())
      .catch(() => onFormSubmitError(true));
  }

  const onFormSubmitSuccess = () => {
    setFormSubmitted(true);
    setBlocking(false);
    setName(""); setEmail(""); setMessage("");
  }

  const onFormSubmitError = () => {
    setFormError(true);
    setBlocking(false);
  }

  const isFormValid = () => {
    return name && email && message;
  }

  return (
    <section id="contact">
      <BlockUi tag="div" blocking={blocking}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>{t('Contact')}</h2>
              <hr className="star-primary" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2">
              <form onSubmit={handleSubmit} name="sentMessage" id="contactForm" noValidate>
                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="name">{t('NameLabel')}</label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} className="form-control" placeholder="Name (required)" id="name" required data-validation-required-message={t('NameRequiredMessage')} />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="email">{t('EmailLabel')}</label>
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Email Address (required)" id="email" required data-validation-required-message={t('EmailRequiredMessage')} />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="row control-group">
                  <div className="form-group col-xs-12 floating-label-form-group controls">
                    <label htmlFor="message">{t('MessageLabel')}</label>
                    <textarea rows="5" value={message} onChange={e => setMessage(e.target.value)} className="form-control" placeholder={t('MessageLabel') + ' (required)'} id="message" required data-validation-required-message={t('MessageRequiredMessage')}></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <br />
                <div hidden={!formSubmitted} className="alert alert-success">
                  <button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                  <strong>{t('MailSent')}</strong>
                </div>
                <div hidden={!formError}>
                  <div className="alert alert-danger">
                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                    <strong>{t('MailError', {name: name})}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-xs-12">
                    <button disabled={!isFormValid()} type="submit" className="btn btn-success btn-lg">{t('SendButton')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </BlockUi>
    </section>
  );
}

export default ContactMe;