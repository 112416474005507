import React from 'react';
import { useTranslation } from 'react-i18next';


function PortfolioModals() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="modal-body">
                                    <h2>{t('RESTAnalytics')}</h2>
                                    <hr className="star-primary" />
                                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                        {/* Indicators */}
                                        <ol className="carousel-indicators hidden">
                                            <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel" data-slide-to="1"></li>
                                            <li data-target="#myCarousel" data-slide-to="2"></li>
                                        </ol>

                                        {/* Wrapper for slides */}
                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <img src="assets/img/portfolio-screenshot/rest/rest1.png" alt="RestAnalytics1" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/rest/rest2.png" alt="RestAnalytics2" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/rest/rest3.png" alt="RestAnalytics3" />
                                            </div>
                                        </div>

                                        {/* Left and right controls */}
                                        <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left"></span>
                                            <span className="sr-only">{t('Previous')}</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right"></span>
                                            <span className="sr-only">{t('Next')}</span>
                                        </a>
                                    </div>
                                    <br />
                                    <p>{t('RestAnalyticsDesc')}</p>
                                    <ul className="list-inline item-details">
                                        <li>
                                            {t('ClientLabel')}:
                                      <strong className="spec">{t('RestAnalyticsClient')}</strong>
                                        </li>
                                        <li>
                                            {t('DateLabel')}:
                                      <strong className="spec">{t('RestAnalyticsDate')}</strong>
                                        </li>
                                        <li>
                                            {t('ServiceLabel')}:
                                      <strong className="spec">{t('RestAnalyticsService')}</strong>
                                        </li>
                                        <li>
                                            {t('TechnologiesLabel')}:
                                      <strong className="spec">{t('RestAnalyticsTechnologies')}</strong>
                                        </li>
                                    </ul>
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> {t('CloseModal')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>{t('Tim')}</h2>
                                    <hr className="star-primary" />
                                    <div id="myCarousel2" className="carousel slide" data-ride="carousel">
                                        {/* Indicators */}
                                        <ol className="carousel-indicators hidden">
                                            <li data-target="#myCarousel2" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel2" data-slide-to="1"></li>
                                            <li data-target="#myCarousel2" data-slide-to="2"></li>
                                            <li data-target="#myCarousel2" data-slide-to="3"></li>
                                        </ol>

                                        {/* Wrapper for slides */}
                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <img src="assets/img/portfolio-screenshot/tim/tim1.png" alt="Tim1" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/tim/tim2.png" alt="Tim2" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/tim/tim3.png" alt="Tim3" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/tim/tim4.png" alt="Tim4" />
                                            </div>
                                        </div>

                                        {/* Left and right controls */}
                                        <a className="left carousel-control" href="#myCarousel2" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left"></span>
                                            <span className="sr-only">{t('Previous')}</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel2" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right"></span>
                                            <span className="sr-only">{t('Next')}</span>
                                        </a>
                                    </div>
                                    <br />
                                    <p>{t('TimDesc')}}</p>
                                    <ul className="list-inline item-details">
                                        <li>
                                            {t('ClientLabel')}:
                                      <strong className="spec">{t('TimClient')}</strong>
                                        </li>
                                        <li>
                                            {t('DateLabel')}:
                                      <strong className="spec">{t('TimDate')}</strong>
                                        </li>
                                        <li>
                                            {t('ServiceLabel')}:
                                      <strong className="spec">{t('TimService')}</strong>
                                        </li>
                                        <li>
                                            {t('TechnologiesLabel')}:
                                      <strong className="spec">{t('TimTechnologies')}</strong>
                                        </li>
                                    </ul>
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> {t('CloseModal')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>{t('T1')}</h2>
                                    <hr className="star-primary" />
                                    <div id="myCarousel3" className="carousel slide" data-ride="carousel">
                                        {/* Indicators */}
                                        <ol className="carousel-indicators hidden">
                                            <li data-target="#myCarousel3" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel3" data-slide-to="1"></li>
                                            <li data-target="#myCarousel3" data-slide-to="2"></li>
                                        </ol>

                                        {/* Wrapper for slides */}
                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <img src="assets/img/portfolio-screenshot/t1hs/t1hs1.png" alt="T1hs1" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/t1hs/t1hs2.png" alt="T1hs2" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/t1hs/t1hs3.png" alt="T1hs3" />
                                            </div>

                                            {/* Left and right controls */}
                                            <a className="left carousel-control" href="#myCarousel3" data-slide="prev">
                                                <span className="glyphicon glyphicon-chevron-left"></span>
                                                <span className="sr-only">{t('Previous')}</span>
                                            </a>
                                            <a className="right carousel-control" href="#myCarousel3" data-slide="next">
                                                <span className="glyphicon glyphicon-chevron-right"></span>
                                                <span className="sr-only">{t('Next')}</span>
                                            </a>
                                        </div>
                                        <br />
                                        <p>{t('T1Desc')}</p>
                                        <ul className="list-inline item-details">
                                            <li>
                                                {t('ClientLabel')}:
                                      <strong className="spec">{t('T1Client')}</strong>
                                            </li>
                                            <li>
                                                {t('DateLabel')}:
                                      <strong className="spec">{t('T1Date')}</strong>
                                            </li>
                                            <li>
                                                {t('ServiceLabel')}:
                                      <strong className="spec">{t('T1Service')}</strong>
                                            </li>
                                            <li>
                                                {t('TechnologiesLabel')}:
                                      <strong className="spec">{t('T1Technologies')}</strong>
                                            </li>
                                        </ul>
                                        <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> {t('CloseModal')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal4" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>{t('GestioneEmergenze')}</h2>
                                    <hr className="star-primary" />
                                    <div id="myCarousel4" className="carousel slide" data-ride="carousel">
                                        {/* Indicators */}
                                        <ol className="carousel-indicators hidden">
                                            <li data-target="#myCarousel4" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel4" data-slide-to="1"></li>
                                            <li data-target="#myCarousel4" data-slide-to="2"></li>
                                            <li data-target="#myCarousel4" data-slide-to="3"></li>
                                            <li data-target="#myCarousel4" data-slide-to="4"></li>
                                        </ol>

                                        {/* Wrapper for slides */}
                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <img src="assets/img/portfolio-screenshot/vallo/vallo1.png" alt="Vallo1" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/vallo/vallo2.png" alt="Vallo2" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/vallo/vallo3.png" alt="Vallo3" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/vallo/vallo4.png" alt="Vallo4" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/vallo/vallo5.png" alt="Vallo5" />
                                            </div>
                                        </div>

                                        {/* Left and right controls */}
                                        <a className="left carousel-control" href="#myCarousel4" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left"></span>
                                            <span className="sr-only">{t('Previous')}</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel4" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right"></span>
                                            <span className="sr-only">{t('Next')}</span>
                                        </a>
                                    </div>
                                    <br />
                                    <p>{t('ValloDesc')}</p>
                                    <ul className="list-inline item-details">
                                        <li>
                                            {t('ClientLabel')}:
                                      <strong className="spec">{t('ValloClient')}</strong>
                                        </li>
                                        <li>
                                            {t('DateLabel')}:
                                      <strong className="spec">{t('ValloDate')}</strong>
                                        </li>
                                        <li>
                                            {t('ServiceLabel')}:
                                      <strong className="spec">{t('ValloService')}</strong>
                                        </li>
                                        <li>
                                            {t('TechnologiesLabel')}:
                                      <strong className="spec">{t('ValloTechnologies')}</strong>
                                        </li>
                                    </ul>
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> {t('CloseModal')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal5" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>{t('Commie')}</h2>
                                    <hr className="star-primary" />
                                    <div id="myCarousel5" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators hidden">
                                            <li data-target="#myCarousel5" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel5" data-slide-to="1"></li>
                                            <li data-target="#myCarousel5" data-slide-to="2"></li>
                                            <li data-target="#myCarousel5" data-slide-to="3"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <img src="assets/img/portfolio-screenshot/commie1.png" alt="Commie1" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/commie2.png" alt="Commie2" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/commie3.png" alt="Commie3" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/commie4.png" alt="Commie4" />
                                            </div>
                                        </div>

                                        <a className="left carousel-control" href="#myCarousel5" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left"></span>
                                            <span className="sr-only">{t('Previous')}</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel5" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right"></span>
                                            <span className="sr-only">{t('Next')}</span>
                                        </a>
                                    </div>
                                    <br />
                                    <p>{t('CommieDesc')}</p>
                                    <ul className="list-inline item-details">
                                        <li>
                                            {t('ClientLabel')}
                                            <strong className="spec">{t('CommieClient')}</strong>
                                        </li>
                                        <li>
                                            {t('DateLabel')}
                                            <strong className="spec">{t('CommieDate')}</strong>
                                        </li>
                                        <li>
                                            {t('ServiceLabel')}
                                            <strong className="spec">{t('CommieService')}</strong>
                                        </li>
                                        <li>
                                            {t('TechnologiesLabel')}
                                            <strong className="spec">{t('CommieTechnologies')}</strong>
                                        </li>
                                    </ul>
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> {t('CloseModal')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio-modal modal fade" id="portfolioModal6" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal">
                        <div className="lr">
                            <div className="rl">
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="modal-body">
                                    <h2>{t('CPC')}</h2>
                                    <hr className="star-primary" />
                                    <div id="myCarousel6" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators hidden">
                                            <li data-target="#myCarousel6" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel6" data-slide-to="1"></li>
                                            <li data-target="#myCarousel6" data-slide-to="2"></li>
                                            <li data-target="#myCarousel6" data-slide-to="3"></li>
                                            <li data-target="#myCarousel6" data-slide-to="4"></li>
                                        </ol>

                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <img src="assets/img/portfolio-screenshot/cpc1.png" alt="CPC1" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/cpc2.png" alt="CPC2" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/cpc3.png" alt="CPC3" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/cpc4.png" alt="CPC4" />
                                            </div>

                                            <div className="item">
                                                <img src="assets/img/portfolio-screenshot/cpc5.png" alt="CPC5" />
                                            </div>
                                        </div>

                                        <a className="left carousel-control" href="#myCarousel6" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left"></span>
                                            <span className="sr-only">{t('Previous')}</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel6" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right"></span>
                                            <span className="sr-only">{t('Next')}</span>
                                        </a>
                                    </div>
                                    <br />
                                    <p>{t('CPCDesc')}</p>
                                    <ul className="list-inline item-details">
                                        <li>
                                            {t('ClientLabel')}
                                            <strong className="spec">{t('CPCClient')}</strong>
                                        </li>
                                        <li>
                                            {t('DateLabel')}
                                            <strong className="spec">{t('CPCDate')}</strong>
                                        </li>
                                        <li>
                                            {t('ServiceLabel')}
                                            <strong className="spec">{t('CPCService')}</strong>
                                        </li>
                                        <li>
                                            {t('TechnologiesLabel')}
                                            <strong className="spec">{t('CPCTechnologies')}</strong>
                                        </li>
                                    </ul>
                                    <button type="button" className="btn btn-default" data-dismiss="modal"><i className="fa fa-times"></i> {t('CloseModal')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PortfolioModals;