import React from 'react';
import { useTranslation } from 'react-i18next';

function PortfolioGrid() {
    const { t } = useTranslation();

    return (
        <section id="portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2>{t('Portfolio')}</h2>
                        <hr className="star-primary" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 portfolio-item">
                        <a href="#portfolioModal5" className="portfolio-link" data-toggle="modal">
                            <div className="caption">
                                <div className="caption-content">
                                    <i className="fa fa-search-plus fa-3x"></i>
                                    <p>{t('Commie')}</p>
                                </div>
                            </div>
                            <img src="assets/img/portfolio/commie.png" className="img-responsive" alt="Commie icon" />
                        </a>
                    </div>
                    <div className="col-sm-6 portfolio-item">
                        <a href="#portfolioModal6" className="portfolio-link" data-toggle="modal">
                            <div className="caption">
                                <div className="caption-content">
                                    <i className="fa fa-search-plus fa-3x"></i>
                                    <p>{t('CPC')}</p>
                                </div>
                            </div>
                            <img src="assets/img/portfolio/cpc.png" className="img-responsive" alt="CPC icon" />
                        </a>
                    </div>
                    <div className="col-sm-6 portfolio-item">
                        <a href="#portfolioModal1" className="portfolio-link" data-toggle="modal">
                            <div className="caption">
                                <div className="caption-content">
                                    <i className="fa fa-search-plus fa-3x"></i>
                                    <p>{t('RESTAnalytics')}</p>
                                </div>
                            </div>
                            <img src="assets/img/portfolio/rest.png" className="img-responsive" alt="RestAnalytics icon" />
                        </a>
                    </div>
                    <div className="col-sm-6 portfolio-item">
                        <a href="#portfolioModal2" className="portfolio-link" data-toggle="modal">
                            <div className="caption">
                                <div className="caption-content">
                                    <i className="fa fa-search-plus fa-3x"></i>
                                    <p>{t('Tim')}</p>
                                </div>
                            </div>
                            <img src="assets/img/portfolio/tim.png" className="img-responsive" alt="TIMreading icon" />
                        </a>
                    </div>
                    <div className="col-sm-6 portfolio-item">
                        <a href="#portfolioModal3" className="portfolio-link" data-toggle="modal">
                            <div className="caption">
                                <div className="caption-content">
                                    <i className="fa fa-search-plus fa-3x"></i>
                                    <p>{t('T1')}</p>
                                </div>
                            </div>
                            <img src="assets/img/portfolio/t1hs.png" className="img-responsive" alt="T1HS icon" />
                        </a>
                    </div>
                    <div className="col-sm-6 portfolio-item">
                        <a href="#portfolioModal4" className="portfolio-link" data-toggle="modal">
                            <div className="caption">
                                <div className="caption-content">
                                    <i className="fa fa-search-plus fa-3x"></i>
                                    <p>{t('GestioneEmergenze')}</p>
                                </div>
                            </div>
                            <img src="assets/img/portfolio/vallo.png" className="img-responsive" alt="Gestione Emergenze icon" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioGrid;