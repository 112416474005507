import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

function About() {
  const { t } = useTranslation();

  return (
    <section className="success" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2>{t('About')}</h2>
            <hr className="star-light" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-lg-offset-2">
            <p>{t('AboutHi')} <br /> {t('AboutWho')}<br /> {t('AboutExp')}</p>
          </div>
          <div className="col-lg-4">
            <p>{t('AboutStudies')} <br /> {t('AboutExpDesc')}</p>
          </div>
          <div className="col-lg-8 col-lg-offset-2 text-center">
            <a href={`assets/static/Fabio_Catuogno_CV_${i18n.language}.pdf`} className="btn btn-lg btn-outline">
              <i className="fa fa-download"></i> {t('AboutCV')}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;